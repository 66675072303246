/* eslint-disable prefer-const */
import PropTypes from 'prop-types';
import Quagga from 'quagga';
import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import './scanner.scss';
import Icon from '../../icons';
import { CHECK, CROSS, CROSS_LARGE, SWITCH2 } from '../../constants/iconNames';
import SlideInPage from '../SlideInPage/SlideInPage';
import IconButton from '../Buttons/IconButton/IconButton';
import quaggaConfig from './quaggaConfig';
import SimpleButton from '../Buttons/SimpleButton/SimpleButton';
import isiOS from '../../utils/isiOS';
function Scanner({ mainHeading, results, onHeaderClick, totalDevices, onDetected }) {
    const [currentDeviceId, setCurrentDeviceId] = useState(0);
    const [deviceIds, setDeviceIds] = useState([]);
    const iOS = isiOS();
    const initScanner = async () => {
        // Make camera lens switching available only for Android
        if (!iOS) {
            // Get all camera sources and filter for videoinputs
            const devices = await navigator.mediaDevices.enumerateDevices();
            const cameras = devices.filter(device => device.kind === 'videoinput');
            // console.log(cameras);
            // Filters out all camera sources not including "back" in label as most devices has this as a part of the label
            // of back camera sources, if none are found after filter we use all
            const filteredBackDeviceIds = cameras.filter(camera => camera.label.includes('back'));
            const foundCameras = filteredBackDeviceIds.length ? filteredBackDeviceIds : cameras;
            // Set all deviceIds found in component state
            const foundCameraDeviceIds = foundCameras.map(foundCamera => foundCamera.deviceId);
            setDeviceIds(foundCameraDeviceIds);
            // Try to get the remembered deviceId from localstorage. If none deviceId found in localstorage or if unknown
            // we use the first found from the deviceIds
            const rememberedDeviceId = localStorage.getItem('deviceId');
            const initDeviceIndex = rememberedDeviceId && foundCameraDeviceIds.includes(rememberedDeviceId)
                ? foundCameraDeviceIds.indexOf(rememberedDeviceId)
                : currentDeviceId;
            setCurrentDeviceId(initDeviceIndex);
            Quagga.init(quaggaConfig(foundCameraDeviceIds[initDeviceIndex]), () => Quagga.start());
        }
        else {
            Quagga.init(quaggaConfig(), () => Quagga.start());
        }
    };
    useEffect(() => {
        try {
            Quagga.stop();
        }
        catch (error) {
            // Do nothing, this is to ensure prev Quagga instance is stopped
        }
        initScanner();
        if (onDetected)
            Quagga.onDetected(onDetected);
        return () => {
            Quagga.offDetected();
            Quagga.stop();
        };
    }, []);
    const toggleCameraLens = deviceId => {
        localStorage.setItem('deviceId', deviceId);
        Quagga.stop();
        Quagga.init(quaggaConfig(deviceId), () => {
            Quagga.start();
        });
    };
    const onCameraChange = () => {
        if (currentDeviceId === deviceIds.length - 1) {
            setCurrentDeviceId(0);
            toggleCameraLens(deviceIds[0]);
        }
        else {
            const deviceIdIndex = currentDeviceId + 1;
            setCurrentDeviceId(deviceIdIndex);
            toggleCameraLens(deviceIds[deviceIdIndex]);
        }
    };
    return ReactDOM.createPortal(React.createElement(SlideInPage, { mainHeading: mainHeading, rightIcon: React.createElement(IconButton, { onClick: onHeaderClick, icon: CROSS_LARGE }) },
        React.createElement("div", { id: "scanner", className: "scanner-container" },
            React.createElement("div", { className: "scanner-container-overlay scanner-container-overlay--top" },
                React.createElement("div", { className: "scanner-result-content" },
                    React.createElement("span", { className: "result-count" }, totalDevices && (React.createElement("div", null,
                        results.filter(result => result.isActivated).length,
                        " / ",
                        totalDevices))),
                    React.createElement("span", { className: "result-list" }, results.map(result => (React.createElement("div", { key: result.serialNumber, className: "list-element" },
                        result.isFetching && !result.isActivated && (React.createElement(ClipLoader, { color: "#fff", size: 12 })),
                        result.isActivated && !result.isFetching && React.createElement(Icon, { name: CHECK, fill: "#fff" }),
                        !result.isActivated && !result.isFetching && (React.createElement("span", { className: "x-cross" },
                            React.createElement(Icon, { name: CROSS, fill: "#fff" }))),
                        React.createElement("span", { className: !result.isActivated && !result.isFetching
                                ? 'serialnumber--error'
                                : 'serialnumber' }, result.serialNumber))))))),
            React.createElement("div", { className: "scanner-container-overlay scanner-container-overlay--bottom" })),
        deviceIds.length > 1 && !iOS && (React.createElement("div", { className: "scanner-container__toggle" },
            React.createElement("div", { className: "scanner-container__toggle__description" }, "Hvis du ikke f\u00E5r scannet, pr\u00F8v \u00E5 endre kameralinse"),
            React.createElement(SimpleButton, { icon: SWITCH2, title: "Endre kameralinse", onClick: onCameraChange })))), document.getElementById('scanner-root'));
}
Scanner.propTypes = {
    mainHeading: PropTypes.string,
    onDetected: PropTypes.func.isRequired,
};
Scanner.defaultProps = {
    mainHeading: 'Aktiver produkter',
    results: [],
};
export default Scanner;
